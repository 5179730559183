import axios from "axios";
import { requestInterceptor, errorInterceptor, responseInterceptor } from "./config.api";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL_TEST,
  headers: {
    "Content-Type": "application/json"
  }
});

httpClient.interceptors.request.use(requestInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;